import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import Formsy from "formsy-react";

import { debounce } from "../utils/index";
import TextField from "../bootstrap/input";
import { setFormData } from "../manager/form";
import {
  findBuildingQuery,
  getFindBuildingResult,
  checkServiceAvailabilityQuery,
} from "../logic/address";

import MapMarkerAltIcon from "../../images/icons/map-marker-alt.svg";
import TimesWhiteImage from "../../images/icons/times-white.svg";
import { console } from "window-or-global";
import ServiceCheckModal from "./service-check";

import { client } from "../main";

export default function AddressAutoComplete(props) {
  const [unitNo, setUnitNo] = useAsyncSetState(null);
  const [addresses, setAddresses] = useAsyncSetState([]);
  const [selectedAddress, setSelectedAddress] = useAsyncSetState(null);
  const [addressError, setAddressError] = useAsyncSetState(false);
  const [error, setError] = useAsyncSetState(null);
  const [unitError, setUnitError] = useAsyncSetState(null);

  const { highlight, home, init, inline, showLocator, setBuilding } = props;
  const rowClassName = `
    vw-address-locator 
    ${inline ? "inline" : ""} 
    ${highlight ? "highlight" : ""} 
    ${showLocator ? "show-address-locator" : "hide-address-locator"} 
    ${init ? "vw-hide" : ""} 
    magictime-300ms
  `;

  async function handleValidSubmit() {
    if (!unitNo) {
      return setUnitError(true);
    }

    if (!selectedAddress) {
      return setAddressError(true);
    }
    console.log({selectedAddress})
    try {
      const response = await client.query({
        fetchPolicy: "no-cache",
        query: checkServiceAvailabilityQuery,
        variables: {
          building: selectedAddress,
          buildingCode: selectedAddress?.code,
          unitNo,
        },
      });
      const serviceAvailability =
        response?.data?.classMethods?.Building?.checkServiceAvailability;
  
      await setFormData({
        building: selectedAddress,
        unit: unitNo,
        serviceAvailability,
      });
  
      return window.location.href = `/register?building=${selectedAddress?.code}&unitno=${unitNo}`;
    } catch (err) {
     
      let errorMessage = err.message.replace(/(GraphQL error:)/gi, "") || err.message;
      return setError(errorMessage || "An error has occured");
    }
  }

  const handleAddressChange = debounce(async (address) => {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: findBuildingQuery,
      variables: { address },
    });
    const result = getFindBuildingResult(response);

    if (!result.length) {
      return setAddressError(true);
    }

    await setAddressError(false);
    return setAddresses(result);
  }, 250);

  const handleAddressSelect = (value) => setSelectedAddress(value);

  return (
    <>
      {error && <ServiceCheckModal error={error} onClose={async () => setError(null)} />}
      <Formsy>
        <div className={rowClassName}>
          <Container fluid className="no-gutters">
            <Row>
              <Col className="vw-al-header">
                {"See if speedytel is available at your address"}
              </Col>
            </Row>
            <Row
              className={`${
                home ? "" : "justify-content-center"
              } flex-align-center no-gutters`}
            >
              <Col xs={12} md={9} lg={8} className="mb-1 mb-md-0">
                <div
                  className={`vw-field ${home ? "highlight" : ""} vw-street`}
                >
                  <div
                    className={`vw-placeholder ${
                      selectedAddress !== null ? "hasValue" : ""
                    }`}
                  >
                    {"Enter your address to check availability"}
                  </div>
                  {selectedAddress !== null ? (
                    <div
                      className="vw-al-selected-address-bg"
                      style={{ backgroundImage: `url(${MapMarkerAltIcon})` }}
                    >
                      <div
                        className="vw-al-selected-address-label"
                        onClick={() => setSelectedAddress(null)}
                        style={{ backgroundImage: `url(${TimesWhiteImage})` }}
                      >
                        {selectedAddress.name}
                      </div>
                    </div>
                  ) : (
                    <input
                      name="address"
                      type="text"
                      autoComplete="off"
                      onChange={async (e) =>
                        await handleAddressChange(e.target.value)
                      }
                      className={`vw-al-input-field ${
                        addressError ? "border-red" : ""
                      }`}
                    />
                  )}
                  {selectedAddress === null && addresses.length > 0 && (
                    <div className="vw-al-locations">
                      {addresses.map((address) => (
                        <div
                          key={`address-${address.id}`}
                          className="vw-al-location"
                          style={{
                            backgroundImage: `url(${MapMarkerAltIcon})`,
                          }}
                          onClick={() => handleAddressSelect(address)}
                        >
                          {address.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={12} md={3} lg={1} className="mb-1 mb-md-0">
                <TextField
                  name="unitNo"
                  className={`${home ? "highlight" : ""}`}
                  inputClass={`input-unit-no ${unitError ? "border-red" : ""}`}
                  onChange={(e) => setUnitNo(e.target.value)}
                  placeholder="Unit #"
                />
              </Col>
              <Col xs={12} lg={"auto"} className="mt-md-1 mt-lg-0 ml-lg-1">
                <div className="vw-field">
                  <button
                    onClick={() => handleValidSubmit()}
                    className={`check-el-btn btn btn-yellow font-black w-100 w-md-unset`}
                  >
                    {"Check address"}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Formsy>
    </>
  );
}
