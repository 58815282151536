import React, { useRef, useEffect, Fragment, useState } from "react";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import { useQuery } from "@apollo/client";

import { getProductsResult, getProductsQuery } from "../logic/products";
import { console } from "window-or-global";
import {Link} from "gatsby";

export default function PlanSection() {
  const myRef = useRef(null);
  const query = useQuery(getProductsQuery, {
    variables: {},
  });
  if (query.loading) {
    return <Fragment />;
  }
  const products = getProductsResult(query);
  const plans = products.filter((value) => value.type === "internet");
  return (
    <Fragment>
      <div className="plan-section" ref={myRef}>
        <Container>
          <Row>
            <Col xs={6} className="mx-auto">

              <div className="plan-header">
                {"Residential Plans"}
              </div>
              {/* <div className="main-text mb-1 text-center">
                Get connected to some of Australia's fastest network
                speeds
              </div>

              <div className="sub-text font-secondary mb-5 text-center">
                Our plans are designed to enhance your ability to share a
                content, stream 4k entertainment and have a seamless gaming
                experience. Choose one of our no-lock-in plans today.
              </div> */}
            </Col>
          </Row>
          <Row>
            {(plans || []).map((plan) => {
              const [wholeNumber = "00", decimal = "00"] = `${
                plan?.value || ""
              }`.split(".");
              return (
                <Col xs={10} sm={8} lg={7} xl={4} className="d-block mx-auto my-3">
                  <div style={{height: "100%"}} className="p-0 mb-1 hover-scale mx-auto mt-0 border-0 d-block">
                    <div className="plan-item  mb-3 text-center h-100 display-product">
                      <div className="plan-title font-primary-bold">
                        {plan.name || ""}
                      </div>
                      <div className="plan-price pt-3">
                        <span className="price-value">{`$${wholeNumber}`}</span>
                        <span className="small-price">{`.${decimal}`}</span>
                        <span className="price-freq">{"/mo."}</span>
                      </div>
                      <div className="plan-rate pb-1">
                        {plan.description || ""}
                      </div>
                      <div className="plan-unlimited mb-3 pb-3">
                        {"Unlimited Data"}
                      </div>
                      {/* <div className="plan-subtitle">
                        <div>{"Typical Evening Speed"}</div>
                        <div>{"47.3 Mbps"}</div>
                      </div> */}

                      <div className="get-it-container">
                        <div className="plan-subtitle bold">
                          {"No lock-in contract"}
                        </div>
                        <Link
                          to="/?section=join"
                          className="btn-wide btn-yellow font-black"
                        >
                          {"Get it"}
                        </Link>
                      </div> 
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <div className="subtitle italic text-center my-5">
                {"*Networks provider (NBN, VostroNet, Opticomm) may charge a New Developments Charge for first connections in a development; these are passed on at cost and are visible at the checkout stage"}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
